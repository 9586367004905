export const DASHBOARD_ROUTE = `/`;
const AUTH_ROUTE = '/auth/';
export const AUTH_LOGIN_ROUTE = `${AUTH_ROUTE}login/`;
export const AUTH_FORGOTTEN_PASSWORD_ROUTE = `${AUTH_ROUTE}forgotten-password/`;
export const AUTH_RESET_PASSWORD_ROUTE = `${AUTH_ROUTE}reset-password/:token/`;
export const PROFILE_ROUTE = `/profile/`;

export const ADMIN_ROUTE = `/admin/`;
export const ADMIN_BULL_ROUTE = `${ADMIN_ROUTE}bull`; // NOTE: Do not add trailing /
export const ADMIN_USERS_ROUTE = `${ADMIN_ROUTE}users/`;
export const ADMIN_USERS_CREATE_ROUTE = `${ADMIN_USERS_ROUTE}create/`;
export const ADMIN_USERS_EDIT_ROUTE = `${ADMIN_USERS_ROUTE}:id/edit/`;
export const ADMIN_PHASE_GROUPS_ROUTE = `${ADMIN_ROUTE}phase-groups/`;
export const ADMIN_PHASE_GROUPS_CREATE_ROUTE = `${ADMIN_PHASE_GROUPS_ROUTE}create/`;
export const ADMIN_PHASE_GROUPS_EDIT_ROUTE = `${ADMIN_PHASE_GROUPS_ROUTE}:id/edit/`;
export const ADMIN_PHASES_ROUTE = `${ADMIN_ROUTE}phases/`;
export const ADMIN_PHASES_CREATE_ROUTE = `${ADMIN_PHASES_ROUTE}create/`;
export const ADMIN_PHASES_EDIT_ROUTE = `${ADMIN_PHASES_ROUTE}:id/edit/`;
export const ADMIN_EXPORTS_ROUTE = `${ADMIN_ROUTE}exports/`;
export const ADMIN_SETTINGS_ROUTE = `${ADMIN_ROUTE}settings/`;

export const ORDERS_ROUTE = `/orders/`;
export const ORDERS_RECEIVED_ROUTE = `${ORDERS_ROUTE}received/`;
export const ORDERS_RECEIVED_SHOW_ROUTE = `${ORDERS_RECEIVED_ROUTE}:id/`;

export const PRODUCTS_ROUTE = `/products/`;
export const PRODUCTS_PRINT_LABEL_ROUTE = `${PRODUCTS_ROUTE}:id/package-label.:ext`;

export const PLANNING_ROUTE = `/planning/`;
export const PLANNING_PRODUCTION_ROUTE = `${PLANNING_ROUTE}production/`;
export const PLANNING_GRAPHIC_DESIGN_ROUTE = `${PLANNING_ROUTE}graphic-design/`;
export const PLANNING_ASSEMBLY_ROUTE = `${PLANNING_ROUTE}assembly/`;

export const TIME_ENTRIES_ROUTE = `/time-entries/`;
export const TIME_ENTRIES_ORDER_RECEIVED_ROUTE = `${TIME_ENTRIES_ROUTE}:orderReceived/`;
export const TIME_ENTRIES_PRODUCT_ROUTE = `${TIME_ENTRIES_ORDER_RECEIVED_ROUTE}:product/`;
export const TIME_ENTRIES_PRODUCT_CREATIVE_ROUTE = `${TIME_ENTRIES_PRODUCT_ROUTE}creative/`;
export const DAY_VIEW_ROUTE = `/day-view/`;

export const EXPORTS_PRODUCTION_ROUTE = '/exports/production.xlsx';
export const EXPORTS_PRODUCT_SPECIFICATIONS_ROUTE = '/exports/product-specifications.xlsx';
export const EXPORTS_TIME_ENTRIES_ROUTE = '/exports/time-entries.xlsx';

export const TESTS_ROUTE = `/tests/` as const;
export const TESTS_LOGIN_ROUTE = `${TESTS_ROUTE}login/` as const;
